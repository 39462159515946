// @flow

import { observable, action } from 'mobx';
import api from '../util/api';

class ActivityModel {
  @observable isMarkedAsRead = false;
  @observable fromNow: string;

  content: string;
  id: number;
  link: string;
  isOpensInNewTab: boolean;
  imageUrl: string;
  isNewBadge: boolean;
  userDisplayName: string;
  hasSettings: boolean;
  settingsActionClass: string;
  badgeName: ?string;
  badgeLevel: ?string;

  isMarkedAsReadCommitted: boolean = false;
  userActivityUpdatedAtUtc: string;

  static createCollection(data) {
    return data.map(activityJson => new ActivityModel(activityJson));
  }

  constructor(data) {
    Object.keys(data).forEach(k => {
      this[k] = data[k];
    });

    if (data.isMarkedAsRead) {
      this.isMarkedAsReadCommitted = true;
    }

    this.updateFromNow();
  }

  @action.bound
  markAsRead(): void {
    this.isMarkedAsRead = true;
  }

  @action.bound
  markAsUnread(): void {
    this.isMarkedAsRead = false;
  }

  @action.bound
  commitMarkAsRead(): void {
    this.isMarkedAsReadCommitted = true;
  }

  @action.bound
  async markAsReadOnServer() {
    try {
      this.isMarkedAsRead = true;
      await api.markAsRead({ id: this.id });
      this.commitMarkAsRead();
    } catch (err) {
      this.isMarkedAsRead = false;
    }
  }

  @action.bound
  updateFromNow() {
    if (this.userActivityUpdatedAtUtc) {
      this.fromNow = moment(new Date(this.userActivityUpdatedAtUtc)).fromNow();
    }
  }
}

export default ActivityModel;
