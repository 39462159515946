import { action, observable, reaction } from 'mobx';
import log from 'shared/utils/log';
import reviewApi from '../util/reviewApi';
import { debounce, noop } from '../util/fns';

export default class ChooseShowPageStore {
  @observable.shallow shows = [];

  searchesInProgress = 0;

  @observable isLoading = false;

  @observable filter = '';

  @observable.ref selectedShow = null;

  filterChangeReactionDisposer = noop;

  constructor(appStore) {
    this.appStore = appStore;

    this.handleFilterChangeDebounced = debounce(this.handleFilterChange, 500, { leading: true });
  }

  init = () => {
    this.isLoading = false;
    this.selectedShow = null;
    this.filter = '';
    this.setShows(this.appStore.runningShows);
    this.filterChangeReactionDisposer = reaction(
      () => this.filter,
      (x) => {
        this.handleFilterChangeDebounced(x);
      }
    );
  };

  @action.bound
  setShows(x) {
    this.shows.replace(x);
  }

  @action.bound
  setLoading(x) {
    this.isLoading = x;
  }

  @action.bound
  setSelectedShow(x) {
    this.selectedShow = x;
  }

  @action.bound
  setFilter(value) {
    this.filter = value;
  }

  handleFilterChange = async (filter) => {
    try {
      if (filter.length === 0) {
        this.setShows(this.appStore.runningShows);

        return;
      }

      this.searchesInProgress += 1;

      this.setLoading(true);

      const data = await reviewApi.searchShow({ q: filter });

      this.setShows(data.shows);
    } catch (error) {
      log(error);
    } finally {
      this.setSearchesInProgress(this.searchesInProgress - 1);

      if (this.searchesInProgress === 0) {
        this.setLoading(false);
      }
    }
  };

  @action.bound
  setSearchesInProgress(x) {
    this.searchesInProgress = x;
  }

  handleShowClick = async (selectedShow) => {
    const seenShowReviewId = this.appStore.seenShows[selectedShow.id];
    const currentReviewId = this.appStore.review?.id;
    this.setSelectedShow(selectedShow);

    if (seenShowReviewId && seenShowReviewId !== currentReviewId) {
      await this.appStore.loadReview(seenShowReviewId);
    } else {
      this.appStore.resetReview();
    }

    this.appStore.review.setShow(selectedShow);
  };
}
