import { action, computed, observable } from 'mobx';

export default class ReviewModel {
  @observable id = null;
  @observable show = null;
  @observable score = null;
  @observable adjectives = [];
  @observable go = '';
  @observable nogo = '';
  @observable more = '';

  @observable showTitle = null;

  @observable originUrl = null;

  @observable.shallow similar = [];

  allowedKeys = ['go', 'nogo', 'more', 'id', 'show', 'score', 'adjectives', 'originUrl', 'similar'];
  arrayKeys = ['adjectives', 'similar'];

  constructor(reviewStore) {
    this.reviewStore = reviewStore;
    this.appStore = reviewStore.appStore;
  }

  export() {
    return {
      id: this.id,
      show: this.show,
      score: this.score,
      adjectives: this.adjectives,
      go: this.go,
      nogo: this.nogo,
      more: this.more,
    }
  }

  @action.bound
  setData(data) {
    Object.keys(data).forEach((k) => {
      if (!this.allowedKeys.includes(k)) return;

      if (this.arrayKeys.includes(k) && Array.isArray(data[k])) {
        this[k].replace(data[k]);
      }

      this[k] = data[k];
    });
  }

  @computed
  get url() {
    if (this.originUrl) return this.originUrl;

    return `/reviews/${this.id}/share`;
  }

  @action.bound
  setOriginUrl(x) {
    this.originUrl = x;
  }

  @action.bound
  setScore(x) {
    this.score = x;
  }

  @action.bound
  setGo(x) {
    this.go = x;
  }

  @action.bound
  setNogo(x) {
    this.nogo = x;
  }

  @action.bound
  setShow(show) {
    this.show = show;
  }

  @action.bound
  setMore(x) {
    this.more = x;
  }

  @action.bound
  setSimilar(x) {
    this.similar.replace(x);
  }

  @action.bound
  reset() {
    this.id = null;

    this.show = null;
    this.score = null;
    this.adjectives.clear();
    this.go = null;
    this.nogo = null;
    this.more = null;
  }

  @action.bound
  addAdjective(x) {
    if (!this.isAddingAdjectivePossible) return;

    if (this.adjectives.some((a) => a === x)) return;

    this.adjectives.push(x);
  }

  @action.bound
  removeAdjective(x) {
    if (this.adjectives.length === 0) return;

    if (this.adjectives.some((a) => a === x)) {
      this.adjectives.remove(x);
    }
  }

  getAdjectiveByIndex = (idx) => {
    if (this.adjectives.length === 0) return null;

    if (this.adjectives.length <= idx) return null;

    return this.adjectives[idx];
  };

  @computed
  get isAddingAdjectivePossible() {
    return this.adjectives.length < 5;
  }

  @computed
  get isAllAdjectivesAdded() {
    return this.adjectives.length === 5;
  }

  @computed
  get showId() {
    if (this.show) {
      return this.show.id;
    }

    return null;
  }
}
