/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable quotes */
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import cn from 'classnames';

class ReviewPage extends Component {
  componentDidMount() {
    this.props.appStore.trackVirtualPageview('go-nogo');
    this.props.appStore.reviewStore.init();
  }

  render() {
    const { appStore } = this.props;
    const { reviewStore } = appStore;

    const { charLimit, moreCharLimit } = reviewStore;
    const { goCharsLeft, isGoFilledOut } = reviewStore;
    const { nogoCharsLeft, isNogoFilledOut } = reviewStore;
    const { moreCharsLeft, isMoreFilledOut } = reviewStore;

    return <>
      <div className="review-modal-v2__title">
        Help other theatre fans discover
        <br className="visible-md-inline-block visible-lg-inline-block"/>
        shows they’ll love
      </div>

      <div className="review-modal-v2__scrollable-block">
        <div className="review-modal-v2__review-part">
          <div className="review-modal-v2__review-part-title">See it if...</div>

          <div className={cn('review-modal-v2__review-part-textarea', {
            'is-error': reviewStore.isGoInvalid && appStore.advanceAttempted
          })}>
              <textarea
                placeholder="What did you like about the show? What will resonate with others?"
                onChange={(e) => {
                  const val = e.target.value.slice(0, charLimit);

                  reviewStore.setGo(val);
                }}
                value={reviewStore.go}
              />

            <div className={cn('review-modal-v2__review-part-counter', {
              'is-almost-done': (goCharsLeft <= 30),
              'is-done': isGoFilledOut
            })}>{goCharsLeft}</div>
          </div>
        </div>

        <div className="review-modal-v2__review-part">
          <div className="review-modal-v2__review-part-title">Don’t see it if...</div>

          <div className={cn('review-modal-v2__review-part-textarea', {
            'is-error': reviewStore.isNoGoInvalid && appStore.advanceAttempted
          })}>
              <textarea
                placeholder="What didn’t you like about the show? What might turn others off?"
                onChange={(e) => {
                  const val = e.target.value.slice(0, charLimit);

                  reviewStore.setNogo(val);
                }}
                value={reviewStore.nogo}
              />

            <div className={cn('review-modal-v2__review-part-counter', {
              'is-almost-done': (nogoCharsLeft <= 30),
              'is-done': isNogoFilledOut
            })}>{nogoCharsLeft}</div>
          </div>
        </div>

        {reviewStore.isMoreVisible ? (
          <div className="review-modal-v2__review-part">
            <div className="review-modal-v2__review-part-title" onClick={() => reviewStore.setMoreVisible(true)}>
              Tell us more...
            </div>
            <div className={cn('review-modal-v2__review-part-textarea -big', {
              'is-error': reviewStore.isMoreInvalid && appStore.advanceAttempted
            })}>
                <textarea
                  placeholder="..."
                  onChange={(e) => {
                    const val = e.target.value.slice(0, moreCharLimit);

                    reviewStore.setMore(val);
                  }}
                  value={reviewStore.more}
                />

              <div className={cn('review-modal-v2__review-part-counter', {
                'is-almost-done': (moreCharsLeft <= 100),
                'is-done': isMoreFilledOut
              })}>{moreCharsLeft}</div>
            </div>
          </div>
        ) : (
          <div className="review-modal-v2__review-more-btn" onClick={() => reviewStore.setMoreVisible(true)}>
            <span>Have more to say?</span>
          </div>
        )}
      </div>
    </>;
  }
}

export default inject('appStore')(observer(ReviewPage));
