/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import AdjectiveItem from './AdjectiveItem';

const SelectedAdjectiveList = ({ advanceAttempted, selectedAdjectives, removeAdjective, getAdjectiveByIndex }) => (
  <div className={cn('review-modal-v2__selected-adjectives', {
    'is-error': advanceAttempted && selectedAdjectives.length === 0
  })}>
    {[0, 1, 2, 3, 4].map((x) => {
      const adjective = getAdjectiveByIndex(x);

      if (!adjective) {
        return <div className="review-modal-v2__selected-adjective -blank" key={x}>{x + 1}</div>;
      }

      return (
        <AdjectiveItem adjective={adjective} key={adjective} removeAdjective={removeAdjective}
                       selectedAdjectives={selectedAdjectives}/>
      );
    })}
  </div>
);

export default observer(SelectedAdjectiveList);
