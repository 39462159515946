import React, { Component } from 'react';
import cn from 'classnames';
import { observer, inject } from 'mobx-react';
import css from './FormField.scss';

export class FormField extends Component {
  handleInputChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    const {
      label,
      inputId,
      errors,
      isShowFormValidationErrors,
      onChange,
      value,
      onValidate,
      ...restProps
    } = this.props;

    const hasErrors = !_.isEmpty(errors) && isShowFormValidationErrors;
    let inputValue = value;

    if (!inputValue) {
      inputValue = '';
    }

    return (
      <div
        className={cn(css['form-field'], 'form-group', {
          'has-error': hasErrors
        })}
      >
        {label && (
          <label className="control-label" htmlFor={inputId}>
            {label}
          </label>
        )}
        <input
          id={inputId}
          value={inputValue}
          onChange={this.handleInputChange}
          className="form-control"
          onBlur={e => {
            onValidate(e.target.value);
          }}
          {...restProps}
        />
        {hasErrors && (
          <div className={css['error-message']}>
            {errors.map(e => (
              <div key={Math.random()}>{e.message}</div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default inject(stores => ({
  isShowFormValidationErrors: stores.store.viewStore.isShowFormValidationErrors
}))(observer(FormField));
