/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import ReviewModal from './ReviewModal';
import AppStore from './stores/AppStore';

window.SS = window.SS || {};
SS.Mobx = SS.Mobx || {};
SS.Mobx.stores = SS.Mobx.stores || {};

export default function ReviewApp() {
  const render = ({ review, seenShows, adjectives, assets, runningShows, onComplete, modalTitle, topShows, guestCookieName }) => {
    // console.log({ review, seenShows, adjectives, assets, runningShows, onComplete, modalTitle });

    const reviewAppStore = new AppStore({
      adjectives,
      assets,
      AuthRequiredModal: SS.AuthRequiredModal,
      modalTitle,
      onComplete,
      review,
      runningShows,
      signedIn: SS.signedIn,
      seenShows,
      scorePositiveThreshold: SS.constants.scorePositiveThreshold,
      scoreMixedThreshold: SS.constants.scoreMixedThreshold,
      topShows,
      guestCookieName,
    });

    SS.Mobx.stores.reviewAppStore = reviewAppStore;

    const targetEl = document.getElementById('react-review-modal');

    ReactDOM.render(<ReviewModal appStore={reviewAppStore} />, targetEl);
  };

  return {
    render,
    React,
    ReactDOM
  };
}
