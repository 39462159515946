import React, { Component } from 'react';
import { Portal } from 'react-portal';
import cn from 'classnames';
import qs from 'qs';
import { observer, inject } from 'mobx-react';
import PaymentProcessingOverlay from 'shared/components/PaymentProcessingOverlay';
import ModalError from 'shared/components/ModalError';
import Wizard from './components/Wizard';
import css from './SignupRootView.scss';

class SignupModal extends Component {
  dom = {};

  componentDidMount() {
    $(document.body)
      .addClass('modal-open')
      .css('padding-right', '15px');
    this.props.store.reset();
    this.props.store.navigationStore.startRouter();
    this.props.store.planStore.init();

    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    const ppmValue = _.get(params, 'ppm');

    if (!ppmValue) return;

    if (ppmValue === '4' || ppmValue === '5') {
      this.props.store.setModalVariant(parseInt(ppmValue, 10));
    }
  }

  componentWillUnmount() {
    $(document.body)
      .removeClass('modal-open')
      .css('padding-right', '');
    this.props.store.navigationStore.stopRouter();
  }

  handleClose = e => {
    if (
      e.targert === this.dom.layoutRef ||
      this.dom.layoutRef.contains(e.target)
    ) {
      return;
    }

    this.props.store.viewStore.hideModal();
  };

  render() {
    const { store } = this.props;

    const { isPaymentProcessing, viewStore, errorsStore } = store;

    const { onScrollableContentRef } = viewStore;

    return (
      <Portal>
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        <div className={css.overlay} onClick={this.handleClose}>
          {/* eslint-enable jsx-a11y/no-static-element-interactions */}
          {errorsStore.hasErrors && (
            <ModalError
              error={errorsStore.lastError}
              closeError={errorsStore.closeError}
              zIndex={9999}
            />
          )}
          <div
            ref={ref => {
              this.dom.layoutRef = ref;
            }}
            className={cn(css.layout, {
              [css['--narrow']]:
                store.modalVariant === 4 || store.modalVariant === 5
            })}
          >
            <div className={css.header}>
              <a
                className={css.close}
                onClick={viewStore.hideModal}
                role="button"
                tabIndex={0}
              >
                &#x2715;
              </a>
            </div>
            <div
              className={css['scrollable-content']}
              ref={onScrollableContentRef}
            >
              <Wizard />
            </div>
            {isPaymentProcessing && (
              <PaymentProcessingOverlay>
                <p>
                  Please wait while we set up your membership.
                  <br />
                  Your card will not be charged until this process is complete.
                </p>
              </PaymentProcessingOverlay>
            )}
          </div>
        </div>
      </Portal>
    );
  }
}

export default inject('store')(observer(SignupModal));
