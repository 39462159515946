import React from 'react';
import { observer } from 'mobx-react';
import css from './MobileCta.scss';

function MobileCta({ title, pill, footer, pillLink }) {
  return <div className={css.wrap}>
      <div className={css.header}>{title}</div>
      <a className={css.pill} href={pillLink}>
        {pill}
      </a>
      <div className={css.footer}>{footer}</div>
    </div>
}

export default observer(MobileCta);
