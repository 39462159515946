import React from 'react';
import { render } from 'react-dom';
import SignupRootContainer from './SignupRootContainer';
import RootStore from './stores/RootStore';

const isDev = process.env.RAILS_ENV === 'development';

window.SS = window.SS || {};
SS.Mobx = SS.Mobx || {};
SS.Mobx.stores = SS.Mobx.stores || {};

const store = new RootStore();

window.SS.Mobx.stores.membership = store;

const SignupRoot = (props, railsContext, domNodeId) => {
  const { user, knownUser } = props;
  const isSignedIn = SS.signedIn;

  store.init({ isDev, isSignedIn, user, knownUser });

  const renderApp = Component => {
    const element = <Component store={store} />;
    render(element, document.getElementById(domNodeId));
  };

  renderApp(SignupRootContainer);
};

export default SignupRoot;
