import ReactOnRails from 'react-on-rails';
import ReviewModalAppV2 from 'bundles/ReviewModal';
import CreditsNavbarWidget from '../bundles/CreditsNavbarWidget';
import Activities from '../bundles/Activities';
import SignupRoot from '../bundles/Membership/SignupRoot';

window.SS = window.SS || {};
window.SS.ReviewModalAppV2 = ReviewModalAppV2;

try {
  ReactOnRails.register({ CreditsNavbarWidget, Activities, SignupRoot });
} catch (err) {
  window.Sentry.captureException(err, {
    extra: { component: 'common bundle: CreditsNavbarWidget, Activities, SignupRoot' },
  });
}
