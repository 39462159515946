import React from 'react';
import { observer } from 'mobx-react';

const ShowFilter = ({ onFilterChange, filterValue }) => (
  <div className="review-modal-v2__search-field">
    <i className="fa-search fal" />
    <input
      onChange={(event) => {
        onFilterChange(event.target.value);
      }}
      placeholder="Enter a show or choose from below"
      type="search"
      value={filterValue}
    />
  </div>
);

export default observer(ShowFilter);
