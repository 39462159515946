import React from 'react';
import { observer } from 'mobx-react';
import Show from './Show';

const ShowList = ({ shows, selectedShow, onShowClick }) => (
  <div className="review-modal-v2__search-results-wrap">
    <ul className="review-modal-v2__search-results">
      {shows.map((show) => (
        <Show key={show.id} onShowClick={onShowClick} selectedShow={selectedShow} show={show}/>
      ))}
    </ul>
  </div>
);

export default observer(ShowList);
