import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import css from './ActivityItem.scss';
import ActivityItemActions from './ActivityItemActions';
import ActivityItemBadge from './ActivityItemBadge';
import ActivityItemImage from './ActivityItemImage';

export function ActivityItem({ activity }) {
  return <div
    className={cn('col-xs-12', css.wrap, {
      'is-activity-unread': !activity.isMarkedAsRead,
      'is-activity-read': activity.isMarkedAsRead
    })}
  >
    <div className="row">
      <div className="col-xs-11">
        <a
          href={activity.link}
          target={activity.isOpensInNewTab ? '_blank' : ''}
          rel="noreferrer"
        >
          <div className="row">
            <div className="col-xs-3">
              {activity.isNewBadge ? (
                <ActivityItemBadge activity={activity} />
              ) : (
                <ActivityItemImage activity={activity} />
              )}
            </div>
            <div className="col-xs-9 activity-inner-content">
              <div dangerouslySetInnerHTML={{ __html: activity.content }} />
              <div className="activity-time-ago">{activity.fromNow}</div>
            </div>
          </div>
        </a>
      </div>
      <ActivityItemActions activity={activity} />
    </div>
  </div>
}

export default observer(ActivityItem);
