import React from 'react';
import { observer, inject } from 'mobx-react';
import ActivitiesDropdown from './ActivitiesDropdown';

function ActivitiesView({ store: { isMainContainerVisible } }) {
  if (!isMainContainerVisible) {
    return null;
  }

  return <ActivitiesDropdown />;
}

export default inject('store')(observer(ActivitiesView));
