// @flow

import { observable, action, computed } from 'mobx';
import log from 'shared/utils/log';
import api from '../utils/api';
import RootStore from './RootStore';

export default class PlanStore {
  rootStore: RootStore;
  @observable selectedPlanId;
  @observable.shallow plansRepository = new Map();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action.bound
  init() {
    this.getPlans();
  }

  @action.bound
  async getPlans() {
    try {
      const data = await api.getPlans();
      const products = data.data;

      if (products.length === 0) throw new Error('no plans loaded from server');

      const plans = products.reduce(
        (acc, product) => [...acc, ...product.plans],
        []
      );

      const plansById = plans.reduce((acc, planJson) => {
        acc[planJson.id] = planJson;
        return acc;
      }, {});

      this.plansRepository.merge(plansById);
    } catch (err) {
      log('error loading plans', err);
    }
  }

  @action.bound
  cancelPlan() {
    this.selectedPlanId = null;
  }

  @action.bound
  choosePlan(planId) {
    this.selectedPlanId = planId;
  }

  @computed
  get plans() {
    return Array.from(this.plansRepository.values());
  }

  @computed
  get selectedPlanTitle() {
    let period;

    if (!this.selectedPlan) {
      return 'No plan selected';
    }

    if (this.isSelectedPlanAnnual) {
      period = 'year';
    } else {
      period = 'month';
    }

    return `${this.selectedPlanName} membership \n @ $${
      this.selectedPlan.amount
    }/${period}`;
  }

  @computed
  get selectedPlanName() {
    if (!this.selectedPlan) {
      return 'No plan selected';
    }

    switch (this.selectedPlan.codename) {
      case 'patron_monthly_plan':
        return 'Patron monthly';
      case 'patron_yearly_plan':
        return 'Patron annual';
      case 'saver_monthly_plan':
        return 'Saver monthly';
      case 'saver_yearly_plan':
        return 'Saver annual';
      default:
        throw new Error('unknown plan');
    }
  }

  @computed
  get supporterPlan() {
    return this.plans.find(p => p.codename === 'saver_monthly_plan');
  }

  @computed
  get patronPlan() {
    return this.plans.find(p => p.codename === 'patron_monthly_plan');
  }

  @computed
  get supporterPlanYearly() {
    return this.plans.find(p => p.codename === 'saver_yearly_plan');
  }

  @computed
  get patronPlanYearly() {
    return this.plans.find(p => p.codename === 'patron_yearly_plan');
  }

  @computed
  get isSelectedPlanAnnual() {
    if (!this.selectedPlan) return false;

    return this.selectedPlan.codename.match(/yearly/i);
  }

  @computed
  get selectedPlan() {
    return this.plans.find(p => p.codename === this.selectedPlanId);
  }
}
