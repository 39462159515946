import React from 'react'
import { observer } from 'mobx-react'

const ScoreLabel = ({ score }) => {
  if (score === null) {
    return <span className="text-muted">Drag to score</span>
  }

  if (score >= 91) {
    return 'Must See!'
  }

  if (score >= 85) {
    return 'Excellent'
  }

  if (score >= 80) {
    return 'Great'
  }

  if (score >= 70) {
    return 'Good'
  }

  if (score >= 50) {
    return 'Average'
  }

  if (score >= 35) {
    return 'Unpopular'
  }

  return 'Bad'
}

export default observer(ScoreLabel)
