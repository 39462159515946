import { action, observable, runInAction } from 'mobx';

export default class AdjectivesPageStore {
  maxAdjectiveCount = 5;

  @observable selectedAdjectives = [];
  @observable adjectives = {
    negative: [],
    positive: [],
  };

  @observable customAdjective = '';

  constructor(appStore, { adjectives }) {
    this.appStore = appStore;
    this.adjectives = adjectives;
  }

  removeAdjective = (adjective) => {
    this.appStore.review.removeAdjective(adjective);
  };

  addAdjective = (adjective) => {
    this.appStore.review.addAdjective(adjective);
  };

  checkIsAdjectiveSelected = (adjective) => this.appStore.review.adjectives.includes(adjective);

  @action.bound
  setCustomAdjective(x) {
    this.customAdjective = x;
  }

  addCustomAdjective = () => {
    if (this.customAdjective.length > 0 && this.customAdjective.length <= 50) {
      this.addAdjective(this.customAdjective);
      runInAction(() => { this.customAdjective = ''; });
    }
  };
}
