import request from 'axios';
import { getAuthToken } from 'shared/utils/api';

export default new (class ReviewApi {
  findShow = async (showId) => {
    const response = await request({
      method: 'GET',
      url: '/shows/search',
      params: { id: showId }
    });

    return response.data;
  };

  searchShow = async ({ q }) => {
    const response = await request({
      method: 'GET',
      url: '/shows/search',
      params: {
        in_current_edition: true,
        q
      }
    });

    return response.data;
  };

  getReview = async ({ id }) => {
    const response = await request({
      method: 'GET',
      url: `/user_reviews/${id}.json`
    });

    return response.data;
  };

  updateReview = async ({ id, data }) =>
    request({
      method: 'PUT',
      url: `/user_reviews/${id}.json`,
      data: {
        authenticity_token: getAuthToken(),
        ...data
      }
    });

  createReview = async ({ data }) =>
    request({
      method: 'POST',
      url: `/user_reviews.json`,
      data: {
        authenticity_token: getAuthToken(),
        ...data
      }
    });
})();
