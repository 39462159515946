import React from 'react';
import { observer } from 'mobx-react';
import css from './ActivitiesDropdownFooter.scss';

function ActivitiesDropdownFooter() {
  return <div className={css.wrap}>
    <i className="fal fa-angle-double-down" />
    SCROLL TO SEE MORE
    <i className="fal fa-angle-double-down" />
  </div>
}

export default observer(ActivitiesDropdownFooter);
