import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import ActivityItem from './ActivityItem';

function ActivitiesList({ activities }) {
  return <Fragment>
      {activities.map(a => (
        <ActivityItem key={a.id} activity={a} />
      ))}
    </Fragment>
}

export default observer(ActivitiesList);
