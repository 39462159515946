import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

const ReviewCard = ({ appStore }) => {
  const { reviewStore } = appStore;
  const { review } = reviewStore;

  return (
    <div className='review-tile-v2'>
      <div className='review-tile-v2__body'>
        <div className="text-center" onClick={() => { if (!reviewStore.publishing) appStore.goToPage(1); }}>
          <div className={cn('review-tile-v2__score score-bg', reviewStore.scoreClassName)}>
            {review.score}
          </div>
          <i className="far fa-edit"/>
        </div>
        <div className='review-tile-v2__review'>
          <div
            className='review-tile-v2__adjectives'
            onClick={() => { if (!reviewStore.publishing) appStore.goToPage(2); }}
          >
            {review.adjectives.join(', ')}{' '}
            <i className="far fa-edit"/>
          </div>
          <p onClick={() => { if (!reviewStore.publishing) appStore.goToPage(3); }}>
            <b>See it if</b>{' '}
            {review.go}{' '}
            <i className="far fa-edit"/>
          </p>
          <p onClick={() => { if (!reviewStore.publishing) appStore.goToPage(3); }}>
            <b>Don’t see it if</b>{' '}
            {review.nogo}{' '}
            <i className="far fa-edit"/>
          </p>
          {review.more &&
            <p
              onClick={() => {
                if (!reviewStore.publishing) { appStore.goToPage(3); reviewStore.setMoreVisible(true); }
              }}
            >
              <b>Also</b>{' '}
              {review.more}{' '}
              <i className="far fa-edit"/>
            </p>
          }
        </div>
      </div>
    </div>
  );
};

export default observer(ReviewCard);
