// @flow

import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Payment from 'shared/components/Payment';
import RootStore from '../../stores/RootStore';
import ContactForm from './ContactForm';
import css from './Checkout.scss';
import BackIcon from './BackIcon';
import Header from './Header';
import Login from '../Login';

type Props = {
  store: RootStore
};

function redirectToMembershipPage() {
  window.location.replace('/membership');
}

export function Checkout({
  store,
  store: { navigationStore, userStore, viewStore, errorsStore, isSkipLoginForm }
}: Props) {
  if (userStore.user.hasPaidMembership) {
    errorsStore.addError('You are already a Show-Score member', {
      onCloseFn: () => {
        viewStore.hideModal();
        redirectToMembershipPage();
      }
    });
  }

  return (
    <div className={css.wrap}>
      <a
        className={css.back}
        onClick={navigationStore.navigateToRoot}
        role="button"
        aria-label="Back"
        tabIndex={-1}
      >
        <BackIcon />
      </a>
      <Header subtitle={store.planStore.selectedPlanTitle} />

      {!(userStore.isSignedIn || isSkipLoginForm) ? (
        <Login />
      ) : (
        <Fragment>
          <div className={css.content}>
            <div className={css.note}>
              Please ensure that the following information is correct
            </div>

            <ContactForm
              mobile
              validateUserField={userStore.validateUserField}
              registerPhoneValidator={userStore.registerPhoneValidator}
              user={userStore.user}
              setUserField={userStore.setUserField}
              validationErrors={userStore.validationErrors}
              isShowFormValidationErrors={viewStore.isShowFormValidationErrors}
              isSignedIn={userStore.isSignedIn}
              isDev={userStore.isDev}
            />
          </div>

          <div className={css.note}>
            By entering your credit card information in the next step, you are opting in to receive text messages from
            us (Patron members only), and you will be charged ${_.get(store, 'planStore.selectedPlan.amount')} per{' '}
            {store.planStore.isSelectedPlanAnnual ? 'year' : 'month'} until you cancel your membership.
            You may cancel at any time on your{' '}
            <a
              href="https://www.show-score.com/promo_tickets"
              target="_blank"
              rel="noopener noreferrer"
            >
              “My Orders” page
            </a>
            . See the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.show-score.com/support/solutions/folders/103000597545"
            >
              FAQs
            </a>{' '}
            for all details.
          </div>
          <div className={css.actions}>
            <Payment
              user={userStore.user}
              order={{ price: store.orderData.amount }}
              onTokenCreated={store.createMembershipSubscription}
              canSubmit={userStore.isUserValid && !store.isPaymentProcessing}
              submitButtonText="ENTER CREDIT CARD"
            />
          </div>
          <div className={css.footer}>
            <a
              href="https://support.show-score.com/support/solutions/articles/103000228277-what-are-member-events-"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
            <span className={css.del}>|</span>{' '}
            <a href="/privacy_policy" target="_blank" rel="noopener noreferrer">
              Privacy
            </a>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default inject('store')(observer(Checkout));
