import React from 'react';
import { observer } from 'mobx-react';
import css from './MobileHeader.scss';

function MobileHeader({ title, subtitle }) {
  return <div className={css.header}>
      <h1 className={css.title}>{title}</h1>
      <p className={css.subtitle}>{subtitle}</p>
    </div>
}

export default observer(MobileHeader);
