import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

function ActivityItemBadge({ activity }) {
  return <span
    className={cn(
      'member-badge full activity-badge-icon',
      activity.badgeName,
      `level-${activity.badgeLevel}`
    )}
  />
}

export default observer(ActivityItemBadge);
