const tooltips = [
  `As soon as you score 6 shows, you'll receive offers to see shows for FREE! <a target="_blank" rel="noopener noreferrer" href="/membernights">Details</a>`,
  `One hour of premium access before everyone else for most* shows. *Excludes occasional invitations directly from productions. <a target="_blank" rel="noopener noreferrer" href="https://support.show-score.com/support/solutions/articles/103000228293-how-do-text-alerts-work-">Details</a>`,
  `Special performances, artists interactions, and more. <a target="_blank" rel="noopener noreferrer" href="https://support.show-score.com/support/solutions/articles/103000228277-what-are-member-events-#priority">Details</a>`,
  `To avoid penalties and ensure seats are filled, members must cancel in advance. <a target="_blank" rel="noopener noreferrer" href="https://support.show-score.com/support/solutions/articles/103000228283-how-do-cancellations-refunds-pardons-and-transfers-work-for-member-nights-">Details</a>`,
  `"Pardons" are for when you need to late-cancel or miss a performance. <a target="_blank" rel="noopener noreferrer" href="https://support.show-score.com/support/solutions/articles/103000228283-how-do-cancellations-refunds-pardons-and-transfers-work-for-member-nights-">Details</a>`,
  `As soon as you score 6 shows, you'll receive offers to see shows for FREE! <a target="_blank" rel="noopener noreferrer" href="membernights">Details</a>`,
  `Choose from over 20 different theater tours, salons, and activities. Limit: 2 per year. <a target="_blank" rel="noopener noreferrer" href="https://support.show-score.com/support/solutions/articles/103000228277-what-are-member-events-#experiences">Details</a>`,
  `Get a free glass of prosecco at Bond 45 when you order any food item, anytime. <a target="_blank" rel="noopener noreferrer" href="https://support.show-score.com/support/solutions/articles/103000228277-what-are-member-events-#bubbly">Details</a>`
];

export default tooltips;
