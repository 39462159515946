import React from 'react'
import ChooseShowPage from './ChooseShowPage'
import ScorePage from './ScorePage'
import AdjectivesPage from './AdjectivesPage'
import ReviewPage from './ReviewPage'
import PreviewPage from './PreviewPage'

 const pages = {
  ChooseShowPage,
  ScorePage,
  AdjectivesPage,
  ReviewPage,
  PreviewPage,
 }

const Page = ({ currentPageClass }) => {
  const Comp = pages[currentPageClass]

  return <Comp />
}

export default Page
