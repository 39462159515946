/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import cn from 'classnames';
import ReviewCard from './ReviewCard';

class PreviewPage extends Component {
  componentDidMount() {
    this.props.appStore.trackVirtualPageview('preview');
  }

  render() {
    const { appStore } = this.props;
    const { reviewStore } = appStore;
    let pageTitle;

    if (reviewStore.review.id) {
      pageTitle = 'Edit your review';
    } else {
      pageTitle = 'Ready to share your review?';
    }

    return <>
      <div className="review-modal-v2__title">{pageTitle}</div>
      <div className="review-modal-v2__preview-subtitle">Preview</div>

      <div className="review-modal-v2__scrollable-block -short">
        <ReviewCard appStore={appStore}/>
      </div>

      <div
        className={cn('review-modal-v2__start-btn', { disabled: reviewStore.publishing })}
        onClick={() => {
          if (!reviewStore.publishing) reviewStore.publish();
        }}
      >
        {reviewStore.publishing ? 'Publishing..' : 'PUBLISH'}
      </div>
    </>;
  }
};

export default inject('appStore')(observer(PreviewPage));
