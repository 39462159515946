/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { observer, Provider } from 'mobx-react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import PageControls from './components/PageControls';
import Page from './components/Page';

function ReviewModal({ appStore }) {

  const { currentPageClass } = appStore

  let animationClassName

  if (appStore.currentPage >= appStore.lastPage) {
    animationClassName = 'page-forward'
  } else {
    animationClassName = 'page-reverse'
  }

  return (
    <Provider appStore={appStore}>
      <div className="review-modal-v2">
        <button aria-label="Close" className="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">×</span>
        </button>
        <TransitionGroup component={null}>
          <CSSTransition key={appStore.currentPage} classNames={animationClassName}
                        timeout={{ enter: 250, exit: 250 }}>
            <div className={cn('review-modal-v2__screen', {
              '-with-controls': appStore.isPageControlsVisible
            })}>
              <Page currentPageClass={currentPageClass}/>
            </div>
          </CSSTransition>
        </TransitionGroup>
        {appStore.isPageControlsVisible && <PageControls appStore={appStore}/>}
      </div>
    </Provider>
  );
}

export default observer(ReviewModal);
