import React from 'react';
import { observer } from 'mobx-react';
import css from './LoadingIndicator.scss';

function LoadingIndicator() {
  return <div className={css.wrap}>
    <i className="fas fa-spinner fa-spin" />
  </div>
}

export default observer(LoadingIndicator);
