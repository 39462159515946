// @flow

import { observable, action } from 'mobx';
import RootStore from './RootStore';

export default class ViewStore {
  @observable step: number = 1;
  @observable displayModal: boolean = false;
  @observable isShowFormValidationErrors: boolean = true;

  rootStore: RootStore;

  scrollableContentRef;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  scrollToPlansTableHeading = () => {
    if (this.scrollableContentRef) {
      $(this.scrollableContentRef).animate(
        {
          scrollTop: 940
        },
        1000
      );
    }
  };

  // Modal Window controls
  @action.bound
  showModal(event) {
    if (_.get(event, 'detail.program') === 'saver') {
      this.rootStore.modalVariant = 4;
    }
    this.displayModal = true;
  }

  @action.bound
  hideModal() {
    this.displayModal = false;
    this.rootStore.navigationStore.navigateToRoot();

    if (this.rootStore.paid) {
      window.location.reload();
    }
  }

  scrollTop = () => {
    if (this.scrollableContentRef) {
      this.scrollableContentRef.scrollTop = 0;
    }
  };

  onScrollableContentRef = ref => {
    this.scrollableContentRef = ref;
  };
}
