/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

const AdjectiveList = ({ adjectives, adjectivesPageStore }) =>
  adjectives.map(adjective => {
    const isSelected = adjectivesPageStore.checkIsAdjectiveSelected(adjective)

    return (
      <div
        key={adjective}
        className={cn('review-modal-v2__common-adjective', {
          'is-selected': isSelected
        })}
        onClick={
          isSelected
            ? () => adjectivesPageStore.removeAdjective(adjective)
            : () => adjectivesPageStore.addAdjective(adjective)
        }
      >
        {adjective}
      </div>
    )
  })

export default observer(AdjectiveList)
