import React from 'react';
import { observer, inject } from 'mobx-react';
import Tooltip from 'bundles/Membership/components/ChoosePlan/PlansTable/Tooltip';
import tooltips from 'bundles/Membership/components/ChoosePlan/PlansTable/tooltips';
import css from '../ChoosePlan4/ChoosePlan4.scss';
import MobileHeader from '../shared/MobileHeader';
import MobileCta from '../shared/MobileCta';

function ChoosePlan5({ store: { viewStore, navigationStore } }) {
  return <div className={css.wrap}>
    <MobileHeader
      title="UPGRADE"
      subtitle="Be first in line & get insider access"
    />
    <div className={css.section}>
      <h3 className={css['section-title']}>BECOME A SHOW-SCORE “PATRON”</h3>
      <p className={css['section-subtitle']}>
        for just $10.99/month:
      </p>
      <ul className={css.list}>
        <li>
          <i className="fal fa-check" />
          <b>First in line for Member Nights</b>
          <br />
          Text alerts when the offer is posted + Patrons-only access for the first hour.{' '}
          <Tooltip iconType="fas" text={tooltips[1]} className={css.tooltip} />
        </li>

        <li>
          <i className="fal fa-check" />
          <b>Save $10 on each Broadway Member Night ticket and save $6 on each Beyond Broadway Member Night ticket!</b>
          <br />
          See more theatre for even less.
        </li>

        <li>
          <i className="fal fa-check" />
          <b>Biggest cancellation window</b>
          <br />2 days in advance (vs. 4 days for free members).{' '}
          <Tooltip iconType="fas" text={tooltips[3]} className={css.tooltip} />
        </li>

        <li>
          <i className="fal fa-check" />
          <b>The Most “Pardons”</b>
          <br />
          Life happens. 6 “Pardons” (vs. one “Pardon” for free members).{' '}
          <Tooltip iconType="fas" text={tooltips[4]} className={css.tooltip} />
        </li>
      </ul>

      <a
        className={css.button}
        onClick={() => navigationStore.navigateToPlan('patron_monthly_plan')}
        role="button"
        tabIndex={-1}
      >
        <div className={css['button-title']}>UPGRADE NOW</div>
        <div className={css['button-subtitle']}>$10.99/MONTH</div>
      </a>

      <div className={css['cancel-warn']}>Cancel anytime</div>
    </div>

    <MobileCta
      title={
        <div>
          <b>ON A TIGHT BUDGET?</b> <br /> Just want per-ticket savings?
        </div>
      }
      pill={
        <div>
          Become a Show-Score Saver
          <br />
          <span>for $2.99/month</span>
        </div>
      }
      pillLink="/membership?ppm=4"
    />

    <div className={css['cta-note']}>
      Read the{' '}
      <a href="https://support.show-score.com/support/solutions/articles/103000228283-how-do-cancellations-refunds-pardons-and-transfers-work-for-member-nights-">
        Frequently Asked Questions
      </a>{' '}
      or see a{' '}
      <a href="/membership">
        side-by-side comparison
      </a>{' '}
      of our membership programs.
    </div>

    <div
      className={css.footer}
      onClick={viewStore.scrollTop}
      role="button"
      tabIndex={-1}
    >
      <button>
        Scroll to the top <i className="fal fa-arrow-up" />
      </button>
    </div>
  </div>
}

export default inject('store')(observer(ChoosePlan5));
