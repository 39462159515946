/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';

const TopShows = ({ shows, onTopShowClick }) => (
    <div className="review-modal-v2__top-shows" >
      {shows.map(show =>
        <img
          key={show.id}
          className="img-responsive review-modal-v2__top-show"
          alt={show.title}
          title={show.title}
          src={show.poster_image_url}
          onClick={() => onTopShowClick(show)}
        />
      )}
    </div>
  );

export default observer(TopShows);
