/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import request from 'axios';
import Dinero from 'dinero.js';
import { observable, action, computed } from 'mobx';
import log from 'shared/utils/log';
import css from './CreditsNavbarWidget.scss';

window.SS = window.SS || {};
SS.Mobx = SS.Mobx || {};
SS.Mobx.stores = SS.Mobx.stores || {};

const GET_CREDITS_URL = '/credit_balance';

const TOOLTIP_TEXT =
  'You have credits that can be used toward your next ticket purchase. Just apply them at checkout. Visit Help for more info.';

class CreditsStore {
  @observable creditsAmount = 0;
  @observable isLoading = false;

  @action.bound
  async refresh() {
    try {
      this.isLoading = true;

      const data = await request({
        method: 'GET',
        url: GET_CREDITS_URL
      });

      this.setCredits(data.data.credit_cents);

      this.isLoading = false;
    } catch (err) {
      log(err);
    }
  }

  @action.bound
  setCredits(value) {
    this.creditsAmount = value;
  }

  @computed
  get formattedCreditsAmount() {
    return Dinero({ amount: this.creditsAmount, currency: 'USD' }).toFormat(
      '$0,0'
    );
  }
}

const creditsStore = new CreditsStore();

window.SS.Mobx.stores.credits = creditsStore;

class CreditsNavbarWidget extends Component {
  dom = {};

  componentDidMount() {
    creditsStore.refresh();
    $(this.dom.root).tooltip();
  }

  render() {
    return (
      <div
        ref={r => {
          this.dom.root = r;
        }}
        className={css.inner}
        title={TOOLTIP_TEXT}
        data-html="true"
        data-toggle="tooltip"
        data-container="body"
        data-placement="bottom"
      >
        <span>
          CREDIT:{' '}
          {creditsStore.isLoading ? '...' : creditsStore.formattedCreditsAmount}
        </span>{' '}
      </div>
    );
  }
}

export default observer(CreditsNavbarWidget);
