import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import css from './Tooltip.scss';

class Tooltip extends Component {
  static defaultProps = {
    iconType: 'fal'
  };

  dom = {};

  componentDidMount() {
    this.activateInfoTooltip();
    window.bindHideTooltipClick();
  }

  activateInfoTooltip = () =>
    $(this.dom.tooltip)
      .tooltip({
        html: true,
        trigger: 'click',
        placement: 'top',
        animation: false,
        title: this.props.text,
        container: 'body'
      })
      .on('mouseenter', function onInfoTooltipMouseEnter() {
        $(this).tooltip('show');
        const tooltipId = $(this).attr('aria-describedby');
        $(`#${tooltipId}`).on('mouseleave', () => $(this).tooltip('hide'));
      })
      .on('mouseleave', function onInfoTooltipMouseLeave() {
        setTimeout(() => {
          if (!$('.tooltip:hover').length) {
            $(this).tooltip('hide');
          }
        }, 300);
      });

  render() {
    const { text, iconType, className } = this.props;

    if (!text) {
      return null;
    }

    return (
      <span
        className={cn(css.wrap, className)}
        role="button"
        aria-label="tooltip"
        tabIndex={-1}
        ref={ref => {
          this.dom.tooltip = ref;
        }}
      >
        <i className={`${iconType} fa-info-circle`} />
      </span>
    );
  }
}

export default observer(Tooltip);
