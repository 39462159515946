import request from 'axios';
import { getAuthToken } from 'shared/utils/api';

export default new class Api {
  getActivities = async params => {
    const response = await request({
      method: 'GET',
      url: '/activities.json',
      params
    });

    return response.data;
  };

  markAllAsRead = async ({ category }) =>
    request({
      method: 'POST',
      url: '/activities/mark_all_as_read',
      data: {
        category,
        authenticity_token: getAuthToken()
      }
    });

  markAsRead = async ({ id }) =>
    request({
      method: 'PUT',
      url: '/activities/mark_as_read',
      data: {
        id,
        authenticity_token: getAuthToken()
      }
    });

  markAllAsSeen = async () =>
    request({
      method: 'POST',
      url: '/activities/mark_all_as_seen',
      data: {
        authenticity_token: getAuthToken()
      }
    });

  checkForNewActivities = async () => {
    const response = await request({
      method: 'GET',
      url: '/activities/last_modified'
    });

    return response.data;
  };
}();
