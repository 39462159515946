import { action, observable } from 'mobx';

export default class SliderStore {
  @observable value = 0;

  constructor(appStore) {
    this.appStore = appStore;
  }

  @action.bound
  init() {
    const score = this.appStore.review ? this.appStore.review.score : 0;
    this.value = score || 0;
  }

  @action.bound
  setValue(x) {
    this.value = x;
  }

  @action.bound
  onChange(e) {
    const val = e.target.value;

    this.setValue(val);

    if (val) {
      this.appStore.review.setScore(Math.round(val));
    }
  }
}
