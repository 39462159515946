// @flow

import React from 'react';
import { Provider } from 'mobx-react';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import SignupRootView from './SignupRootView';

export default class SignupRootContainer extends React.Component {
  componentDidMount() {
    document.addEventListener(
      'membership:modal:show',
      this.props.store.viewStore.showModal
    );
    document.addEventListener(
      'membership:modal:hide',
      this.props.store.viewStore.hideModal
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'membership:modal:show',
      this.props.store.viewStore.showModal
    );
    document.removeEventListener(
      'membership:modal:hide',
      this.props.store.viewStore.hideModal
    );
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <ErrorBoundary>
          <SignupRootView />
        </ErrorBoundary>
      </Provider>
    );
  }
}
