// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import log from 'shared/utils/log';
import PhoneNumberField from 'shared/components/PhoneNumberField';
import type {
  ValidationErrorType,
  UserType
} from 'shared/stores/UserStore';
import FormField from './FormField';

type Props = {
  user: UserType,
  setUserField: (fieldName: string, fieldValue: string) => void,
  validateUserField: (fieldName: string, fieldValue: string) => void,
  validationErrors: Array<ValidationErrorType>,
  isShowFormValidationErrors: boolean,
  isSignedIn: boolean,
  isDev: boolean
};

class ContactForm extends Component<Props> {
  isValueLoggable = value =>
    !this.props.isSignedIn && value && value.length >= 2;

  track = eventName => {
    if (this.props.isDev) {
      log(eventName);
    }
  };

  handleFirstNameChange = value => {
    this.props.setUserField('firstName', value);

    if (
      !window.isMembershipContactFormFirstNameEntered &&
      this.isValueLoggable(value)
    ) {
      window.isMembershipContactFormFirstNameEntered = true;
      this.track('membership order form: First Name filled in');
    }
  };

  handleLastNameChange = value => {
    this.props.setUserField('lastName', value);

    if (
      !window.isMembershipContactFormLastNameEntered &&
      this.isValueLoggable(value)
    ) {
      window.isMembershipContactFormLastNameEntered = true;
      this.track('membership order form: Last Name filled in');
    }
  };

  handleEmailChange = value => {
    this.props.setUserField('email', value);

    if (
      !window.isMembershipContactFormEmailEntered &&
      this.isValueLoggable(value) &&
      value.match(/.+@.+\..+/)
    ) {
      window.isMembershipContactFormEmailEntered = true;
      this.track('membership order form: Email filled in');
    }
  };

  handlePhoneNumberNameChange = value => {
    this.props.setUserField('phoneNumber', value);

    if (
      window.isMembershipContactFormPhoneEntered &&
      this.isValueLoggable(value) &&
      value.length >= 7
    ) {
      window.isMembershipContactFormPhoneEntered = true;
      this.track('membership order form: Phone Number filled in');
    }
  };

  handleUserFieldValidation = fieldName => value => {
    this.props.validateUserField(fieldName, value);
  };

  render() {
    const {
      user,
      validationErrors,
      header,
      isShowFormValidationErrors,
      footer,
      registerPhoneValidator
    } = this.props;

    return (
      <div className="contact-form">
        {header && header}
        <div className="row slim-gutters">
          <div className="col-xs-6">
            <FormField
              isShowFormValidationErrors={isShowFormValidationErrors}
              errors={validationErrors.filter(e => e.field === 'firstName')}
              inputId="firstname"
              placeholder="ENTER FIRST NAME"
              label="FIRST NAME"
              onValidate={this.handleUserFieldValidation('firstName')}
              onChange={this.handleFirstNameChange}
              value={user.firstName}
              type="text"
            />
          </div>
          <div className="col-xs-6">
            <FormField
              errors={validationErrors.filter(e => e.field === 'lastName')}
              inputId="lastname"
              placeholder="ENTER LAST NAME"
              label="LAST NAME"
              onChange={this.handleLastNameChange}
              onValidate={this.handleUserFieldValidation('lastName')}
              isShowFormValidationErrors={isShowFormValidationErrors}
              value={user.lastName}
              type="text"
            />
          </div>
        </div>

        <div className="row slim-gutters">
          <div className="col-md-12">
            <FormField
              isShowFormValidationErrors={isShowFormValidationErrors}
              errors={validationErrors.filter(e => e.field === 'email')}
              inputId="email"
              placeholder="ENTER EMAIL ADDRESS"
              label="EMAIL"
              value={user.email}
              onChange={this.handleEmailChange}
              onValidate={this.handleUserFieldValidation('email')}
              type="email"
            />
          </div>
        </div>

        <div className="row slim-gutters">
          <div className="col-md-12">
            <PhoneNumberField
              value={user.phoneNumber}
              errors={validationErrors.filter(e => e.field === 'phoneNumber')}
              onChange={this.handlePhoneNumberNameChange}
              onValidate={this.handleUserFieldValidation('phoneNumber')}
              isShowFormValidationErrors={isShowFormValidationErrors}
              registerPhoneValidator={registerPhoneValidator}
            />
          </div>
        </div>

        {footer && footer}
      </div>
    );
  }
}

export default observer(ContactForm);
