/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { observer } from 'mobx-react';

class Slider extends Component {
  componentDidMount() {
    this.props.sliderStore.init();
  }

  render() {
    const { sliderStore } = this.props

    return (
      <div className="review-modal-v2__score-slider">
        <div className="review-modal-v2__score-slider-range">
          <span>0</span>
          <span>100</span>
        </div>
        <input
          type="range"
          name="score"
          id="score"
          value={sliderStore.value}
          max="100"
          min="0"
          onChange={sliderStore.onChange}
        />
      </div>
    )}
}

export default observer(Slider);
