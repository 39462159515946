import React from 'react';
import { observer } from 'mobx-react';

function ActivityItemImage({ activity }) {
  if (activity.imageUrl) {
    return (
      <img src={activity.imageUrl} className="activity-img" alt="activity" />
    );
  }

  return (
    <span className="member-inactive-img user-tile-image-placeholder">
      {activity.userDisplayName ? activity.userDisplayName[0] : ''}
    </span>
  );
}

export default observer(ActivityItemImage);
