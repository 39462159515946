import request from 'axios';
import { getAuthToken } from '../../../shared/utils/api';

const PLANS_PATH = `/memberships/subscriptions/new.json`;
const CREATE_PLAN_PATH = `/memberships/subscriptions.json`;

export default new class Api {
  getPlans = () =>
    request({
      method: 'GET',
      url: PLANS_PATH
    });

  createMembershipSubscription = ({ token, planId, user }) => request({
      method: 'POST',
      url: CREATE_PLAN_PATH,
      data: {
        token,
        plan_id: planId,
        user,
        authenticity_token: getAuthToken()
      }
    });

  login = ({ email, password }) =>
    request({
      url: '/teapi/sign_in.json',
      method: 'POST',
      data: {
        user: {
          email,
          password
        },
        authenticity_token: getAuthToken()
      }
    });
}();
