import React from 'react';
import cn from 'classnames';
import { observer, inject } from 'mobx-react';
import css from './ActivitiesDropdownHeader.scss';

function ActivitiesDropdownHeader({ store }) {
  return <div className={css.wrap}>
    <div className={css.buttons}>
      <button
        className={cn(css.button, {
          [css['button-active']]: store.displayCategory === 'offers'
        })}
        onClick={store.displayOffersCategory}
      >
        <i className="fas fa-bullhorn" /> OFFERS ({store.offersTotal})
      </button>
      <button
        className={cn(css.button, {
          [css['button-active']]: store.displayCategory === 'alerts'
        })}
        onClick={store.displayAlertsCategory}
      >
        <i className="fas fa-bell" /> ALERTS ({store.alertsTotal})
      </button>
    </div>

    <div className={css.actions}>
      {store.displayCategory === 'offers' && store.unreadOffersCount > 0 && (
        <a href="#" onClick={store.markAllOffersAsRead}>
          Mark All Offers as Read
        </a>
      )}

      {store.displayCategory === 'alerts' && store.unreadAlertsCount > 0 && (
        <a href="#" onClick={store.markAllAlertsAsRead}>
          Mark All Alerts as Read
        </a>
      )}

      <a href="#" onClick={store.hideMainContainer} className="pull-right">
        Close
      </a>
    </div>
  </div>
}

export default inject('store')(observer(ActivitiesDropdownHeader));
