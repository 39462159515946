// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
// import ChoosePlan from '../ChoosePlan';
// import ChoosePlan2 from '../ChoosePlan2';
import ChoosePlan4 from '../ChoosePlan4';
import ChoosePlan5 from '../ChoosePlan5';
import Checkout from '../Checkout';
import Thanks from '../Thanks';

const ChoosePlanViewVariants = {
  // 1: ChoosePlan,
  // 2: ChoosePlan2,
  4: ChoosePlan4,
  5: ChoosePlan5
};

function Wizard({
  store: {
    modalVariant,
    navigationStore: { currentView }
  }
}) {
  // if (!currentView) return <ChoosePlan />;
  if (!currentView) { return false; }

  const ChoosePlanView = ChoosePlanViewVariants[modalVariant];

  const component = {
    root: <ChoosePlanView />,
    payment: <Checkout />,
    thanks: <Thanks />
  }[currentView.name];

  // return component || <ChoosePlan />;
  return component;
};

export default inject('store')(observer(Wizard));
