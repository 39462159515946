// @flow

import { observable, action, computed } from 'mobx';
import RootStore from './RootStore';

export type ErrorType = {
  message: string,
  type: string
};

export default class ErrorsStore {
  rootStore: RootStore;
  @observable.shallow errors: Array<ErrorType> = [];

  onCloseFn;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get hasErrors() {
    return this.errors.slice().length > 0;
  }

  @computed
  get lastError() {
    return _.last(this.errors.slice());
  }

  @action.bound
  addError(message: string, options = {}) {
    this.errors.push({ message });

    if (options.onCloseFn) this.onCloseFn = options.onCloseFn;
  }

  @action.bound
  closeError() {
    this.errors.clear();
    if (this.onCloseFn && typeof this.onCloseFn === 'function')
      this.onCloseFn();
  }
}
