/* eslint-disable no-new */
import { action, computed, observable } from 'mobx';
import ChooseShowPageStore from './ChooseShowPageStore';
import SliderStore from './SliderStore';
import ReviewStore from './ReviewStore';
import AdjectivesPageStore from './AdjectivesPageStore';

const ChooseShowPage = 'ChooseShowPage';
const ScorePage = 'ScorePage';
const AdjectivesPage = 'AdjectivesPage';
const ReviewPage = 'ReviewPage';
const PreviewPage = 'PreviewPage';

const getScoreStr = () => 'score';

export default class AppStore {
  seenShows = {};

  guestCookieName = null;

  @observable topShows = [];

  @observable.shallow runningShows = [];

  @observable adjectives = [];

  @observable currentPage = 0;

  @observable isLoading = false;

  @observable advanceAttempted = false;

  @observable lastPage = null;

  onComplete;

  modalTitle;

  basePages = [ChooseShowPage, ScorePage, AdjectivesPage, ReviewPage, PreviewPage];

  remaining = 0;

  signedIn = null;
  scoreMixedThreshold = 50;
  scorePositiveThreshold = 70;

  @observable animationClassName = 'page-forward';

  constructor({
                adjectives,
                assets,
                review,
                modalTitle,
                AuthRequiredModal,
                signedIn,
                runningShows,
                seenShows,
                onComplete,
                topShows,
                guestCookieName,
                scoreMixedThreshold,
                scorePositiveThreshold
              }) {
    this.assets = assets;
    this.AuthRequiredModal = AuthRequiredModal;
    this.signedIn = signedIn;
    this.runningShows = runningShows;
    this.seenShows = seenShows;
    this.onComplete = onComplete;
    this.modalTitle = modalTitle;
    this.scoreMixedThreshold = scoreMixedThreshold;
    this.scorePositiveThreshold = scorePositiveThreshold;
    this.topShows = topShows;
    this.guestCookieName = guestCookieName;

    this.reviewStore = new ReviewStore(this, review);
    this.chooseShowPageStore = new ChooseShowPageStore(this);
    this.sliderStore = new SliderStore(this);
    this.adjectivesPageStore = new AdjectivesPageStore(this, { adjectives });

    this.init();
  }

  @action.bound
  setLoading(x) {
    this.isLoading = x;
  }

  handleTopShowClick = async (topShow) => {
    await this.chooseShowPageStore.handleShowClick({ id: topShow.id, title: topShow.title });
    this.nextPage();
  };

  @computed
  get pages() {
    return [...this.basePages];
  }

  @computed
  get pagesTotal() {
    return this.pages.length;
  }

  get review() {
    if (this.reviewStore) {
      return this.reviewStore.review;
    }

    return null;
  }

  @computed
  get initialPage() {
    if (this.review.id || (this.signedIn && this.review.go && this.review.nogo)) {
      return 4;
    }

    if (this.review.show) {
      return 1;
    }

    return 0;
  }

  @action.bound
  init() {
    this.setCurrentPage(this.initialPage);

    this.setLastPage(0);

    if (window.location.pathname === '/contest') {
      this.setLoading(false);
    } else {
      this.setLoading(Boolean(this.review.show));
    }

    this.advanceAttempted = false;

    this.preloadAssetsAndReview();
  }

  onLoad = () => {
    this.remaining -= 1;

    if (this.remaining === 0 && this.isLoading) {
      setTimeout(() => this.setLoading(false), 700);
    }
  };

  preloadAssetsAndReview = () => {
    const { id } = this.review;
    this.remaining = this.assets.length;

    if (id) {
      this.remaining += 1;
    }

    this.assets.forEach((src) => {
      const i = new Image();
      i.addEventListener('load', this.onLoad);
      i.src = src;
    });

    if (id) {
      this.loadReview(id);
    }
  };

  resetReview = () => {
    this.reviewStore.reset();
  };

  loadReview = async (id) => {
    await this.reviewStore.load(id);
    this.onLoad();
  };

  nextPage = () => {
    /* if the user is not signed in, redirect to sign in page */
    if (!this.signedIn && this.currentPage === 3) {
      window.Cookies.set(this.guestCookieName, this.reviewStore.review.export(), { expire: 30 });

      new this.AuthRequiredModal({ data: getScoreStr }, { show_id: this.review.show.id });

      return;
    }

    this.setAdvanceAttempted(true);
    if (!this.canAdvanceFromCurrentPage) return;

    this.setAnimationClassName('page-forward');
    this.setCurrentPage(Math.min(this.pagesTotal, this.currentPage + 1));
    this.setLastPage(this.currentPage);
    this.setAdvanceAttempted(false);
  };

  previousPage = () => {
    if (this.currentPage === 0) return;

    this.setAnimationClassName('page-reverse');
    this.setCurrentPage(Math.max(0, this.currentPage - 1));
    this.setLastPage(this.currentPage);
    this.setAdvanceAttempted(false);
  };

  goToPage = (x) => {
    if (x > this.currentPage && !this.canAdvanceFromCurrentPage) {
      this.setAdvanceAttempted(true);
      return;
    }

    const pageToGo = Math.max(0, Math.min(this.pagesTotal, x));

    if (pageToGo >= this.lastPage) {
      this.setAnimationClassName('page-forward');
    } else {
      this.setAnimationClassName('page-reverse');
    }

    this.setCurrentPage(pageToGo);
  };

  @action.bound
  setAnimationClassName(x) {
    this.animationClassName = x;
  }

  @action.bound
  setLastPage(x) {
    this.lastPage = x;
  }

  @action.bound
  setCurrentPage(x) {
    this.currentPage = x;
  }

  @action.bound
  setAdvanceAttempted(x) {
    this.advanceAttempted = x;
  }

  handleScoreChange = (val) => {
    if (!val) return;
    this.review.setScore(Math.round(val * 100));
  };

  @computed
  get currentPageClass() {
    return this.pages[this.currentPage];
  }

  @computed
  get canAdvanceFromCurrentPage() {
    const canAdvanceFromPage0 = Boolean(this.review.show);
    const canAdvanceFromPage1 = canAdvanceFromPage0 && this.review.score !== null;
    const canAdvanceFromPage2 = canAdvanceFromPage1 && !!this.review.adjectives.length;
    const canAdvanceFromPage3 = canAdvanceFromPage2 && !!(this.review.go && this.review.nogo);

    return {
      0: canAdvanceFromPage0,
      1: canAdvanceFromPage1,
      2: canAdvanceFromPage2,
      3: canAdvanceFromPage3,
      4: true,
      5: true
    }[this.currentPage];
  }

  @computed
  get isPageControlsVisible() {
    return [1, 2, 3].includes(this.currentPage);
  }

  publishReview = () => {
    this.review.publish();
  };

  generateVirtualPageViewEvent = (pageName, showSlug = null) => {
    let page = `${window.location.pathname}#/review`;

    if (showSlug && showSlug.length > 0) {
      page += `/${showSlug}`;
    }

    page += `/${pageName}`;

    let pageTitle = `review app: ${pageName}`;

    if (!SS.signedIn) {
      pageTitle = `${pageTitle} [guest]`;
    }

    return {
      event: 'VirtualPageview',
      virtualPageUrl: page,
      virtualPageTitle: pageTitle
    };
  };

  trackGAEvent = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  };

  trackVirtualPageview = (pageName) => {
    window.SS.showSlugsCache ||= {};
    const { showId } = this.reviewStore.review;

    if (!showId) {
      this.trackGAEvent(this.generateVirtualPageViewEvent(pageName));
      return;
    }

    const slug = SS.showSlugsCache[showId];

    if (slug) {
      this.trackGAEvent(this.generateVirtualPageViewEvent(pageName, slug));
      return;
    }

    $.getJSON('/shows/search', { id: showId }, (response) => {
      this.trackGAEvent(this.generateVirtualPageViewEvent(pageName, response.slug));
      window.SS.showSlugsCache[showId] = response.slug;
    });
  };
}
