import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import cn from 'classnames';
import ScoreLabel from './ScoreLabel';
import Slider from '../Slider';

class ScorePage extends Component {
  componentDidMount() {
    this.props.appStore.trackVirtualPageview('set-score');
    this.props.appStore.reviewStore.setShowTitle();
  }

  render() {
    const { appStore } = this.props;
    const { score } = appStore.review;

    return <>
      <div className="review-modal-v2__title">
        What did you think of
        <div className="review-modal-v2__show-title">{appStore.review.showTitle}</div>
      </div>

      <div className="review-modal-v2__subtitle">Move the slider to rate the show</div>

      <div className="review-modal-v2__score-container">
        <div className="review-modal-v2__score-wrap">
          <div
            className={cn('review-modal-v2__score', appStore.reviewStore.scoreClassName)}>
            {score === null ? '-' : score}
          </div>
        </div>
      </div>
      <div className={cn('review-modal-v2__score-desc', {
        'is-error': appStore.advanceAttempted && score === null
      })}>
        <ScoreLabel score={score}/>
      </div>
      <Slider sliderStore={appStore.sliderStore}/>
    </>;
  };
}

export default inject('appStore')(observer(ScorePage));
