/* eslint-disable no-alert */
/* eslint-disable no-undef */
import { action, computed, observable } from 'mobx';
import ReviewModel from './ReviewModel';
import reviewApi from '../util/reviewApi';

export default class ReviewStore {
  @observable publishing = false;

  @observable isMoreVisible = false;

  review = null;

  @observable go = '';
  @observable nogo = '';
  @observable more = '';

  charLimit = 140;
  moreCharLimit = 1000;

  @observable isLoading = false;

  constructor(appStore, reviewData) {
    this.appStore = appStore;

    this.review = new ReviewModel(this);

    if (reviewData) {
      this.review.setData(reviewData);
    }
  }

  @action.bound
  init() {
    this.go = this.review.go || '';
    this.nogo = this.review.nogo || '';
    this.more = this.review.more || '';
  }

  @computed
  get isGoInvalid() {
    return this.go.length === 0 || this.go.length > this.charLimit;
  }

  @computed
  get isNoGoInvalid() {
    return this.nogo.length === 0 || this.nogo.length > this.charLimit;
  }

  @computed
  get isMoreInvalid() {
    return this.more.length > this.moreCharLimit;
  }

  @computed
  get goCharsLeft() {
    return this.charLimit - this.go.length;
  }

  @computed
  get isGoFilledOut() {
    return this.goCharsLeft === 0;
  }

  @computed
  get nogoCharsLeft() {
    return this.charLimit - this.nogo.length;
  }

  @computed
  get isNogoFilledOut() {
    return this.nogoCharsLeft === 0;
  }

  @computed
  get moreCharsLeft() {
    return this.moreCharLimit - this.more.length;
  }

  @computed
  get isMoreFilledOut() {
    return this.moreCharsLeft === 0;
  }

  @action.bound
  setGo(x) {
    this.go = x;
    this.review.go = x;
  }

  @action.bound
  setNogo(x) {
    this.nogo = x;
    this.review.nogo = x;
  }

  @action.bound
  setMore(x) {
    this.more = x;
    this.review.more = x;
  }

  @action.bound
  setMoreVisible(x) {
    this.isMoreVisible = x;
  }

  @action.bound
  reset() {
    this.review.reset();
  }

  load = async (id) => {
    this.setLoading(true);
    const reviewId = id || this.review.id;

    const reviewData = await reviewApi.getReview({ id: reviewId });
    this.review.setData(reviewData);
    this.setLoading(false);
  };

  handleEditClick = () => {
    if (!this.publishing) this.appStore.goToPage(1);
  };

  @computed
  get scoreKind() {
    if (this.review.score >= this.appStore.scorePositiveThreshold) return 'positive';

    if (this.review.score >= this.appStore.scoreMixedThreshold) return 'mixed';

    return 'negative';
  }

  @action.bound
  setLoading(x) {
    this.isLoading = x;
  }

  @action.bound
  setPublishing(x) {
    this.publishing = x;
  }

  @computed
  get scoreClassName() {
    if (this.review.score === null) {
      return null;
    }

    return `-${this.scoreKind} ${this.scoreKind}`;
  }

  getReviewData = () => ({
    id: this.review.id,
    adjectives: this.review.adjectives.slice(),
    go: this.review.go,
    more: this.review.more,
    nogo: this.review.nogo,
    score: this.review.score,
    show_id: this.review.show.id
  });

  getReviewDataWithShow = () => ({ ...this.getReviewData(), show: this.review.show });

  createOrUpdateReview = (reviewData) => {
    if (this.review.id) {
      return reviewApi.updateReview({ id: this.review.id, data: reviewData });
    }
    return reviewApi.createReview({ data: reviewData });
  };

  publish = async () => {
    this.setPublishing(true);

    try {
      const reviewData = this.getReviewData();

      const data = await this.createOrUpdateReview(reviewData);

      // if (data.data?.similar) {
      //   this.review.setSimilar(data.data.similar);
      // }

      if (data.data?.url) {
        this.review.setOriginUrl(data.data.url);
        window.location = data.data.url;
      }

      // this.appStore.nextPage();
    } catch (error) {
      if (error.response.status === 401) {
        window.location = '/users/sign_in';
      } else {
        this.setPublishing(false);
        alert('There was an error saving your review, please try again');
      }
    } finally {
      this.appStore.onComplete?.(this.getReviewDataWithShow());
      // this.setPublishing(false);
    }
  };

  setShowTitle = () => {
    const showId = this.review.show.id;
    const show = this.appStore.runningShows.find((s) => s.id === showId);

    if (show) {
      this.review.showTitle = show.title;
      return;
    }

    reviewApi.findShow(showId)
      .then((foundShow) => {
        this.review.showTitle = foundShow.title;
      })
      .catch(() => {
        this.review.showTitle = null;
      });
  };
}
