/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import PageControlsDot from './PageControlsDot';

const PageControls = ({ appStore }) => (
  <div className="review-modal-v2__nav">
    <div
      className={cn('review-modal-v2__nav-back', {
        'is-hidden': !appStore.currentPage === 0,
      })}
      onClick={appStore.previousPage}
    >
      <i className="fa-chevron-left far"/>
      <span>Back</span>
    </div>

    <div className="review-modal-v2__nav-middle">
      <PageControlsDot page={1} appStore={appStore} />

      <PageControlsDot page={2} appStore={appStore} />

      <PageControlsDot page={3} appStore={appStore} />
    </div>

    <div
      className={cn('review-modal-v2__nav-next', {
        'is-disabled': !appStore.canAdvanceFromCurrentPage,
      })}
      onClick={appStore.nextPage || appStore.setAdvanceAttempted}
    >
      <span>Next</span>
      <i className="fa-chevron-right far"/>
    </div>
  </div>
);

export default observer(PageControls);
