import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import ActivitiesView from './components/ActivitiesView';
import ActivitiesRootStore from './stores/ActivitiesRootStore';

const BUTTON_SELECTOR = '.js-activity-feed__dropdown-btn';
const DESKTOP_BUTTON_MOD = '.js-activity-feed__dropdown-btn--desktop';
const ACTIVITY_FEED_INDICATOR_CLASS_NAME = 'js-activity-feed__desktop-indicator';

class ActivitiesRootContainer extends Component {
  constructor(props) {
    super(props);
    this.store = new ActivitiesRootStore();
    window.SS.Mobx.stores.activities = this.store;
  }

  dom = {};

  componentDidMount() {
    let buttonQuery = BUTTON_SELECTOR;

    if (!SS.Viewport.is('xs')) {
      buttonQuery = `${buttonQuery}${DESKTOP_BUTTON_MOD}`;
    }

    this.dom.toggleActivitiesDropdownButton = document.querySelector(
      buttonQuery
    );

    if (this.dom.toggleActivitiesDropdownButton) {
      this.dom.toggleActivitiesDropdownButton.addEventListener(
        'click',
        this.store.toggleMainContainerVisibility
      );
    }

    this.getPositionForActivitiesDropdown();

    document.addEventListener('scroll', this.getPositionForActivitiesDropdown);

    const activityFeedIndicatorElements = document.getElementsByClassName(
      ACTIVITY_FEED_INDICATOR_CLASS_NAME
    );

    if (activityFeedIndicatorElements) {
      this.store.setActivityFeedIndicatorElements(
        activityFeedIndicatorElements
      );
    }
  }

  componentWillUnmount() {
    if (this.dom.toggleActivitiesDropdownButton) {
      this.dom.toggleActivitiesDropdownButton.removeEventListener(
        'click',
        this.store.toggleMainContainerVisibility
      );
    }

    document.removeEventListener(
      'scroll',
      this.getPositionForActivitiesDropdown
    );
  }

  getPositionForActivitiesDropdown = () => {
    const button = this.dom.toggleActivitiesDropdownButton;
    if (button) {
      const rect = button.getBoundingClientRect();
      this.store.setButtonRect({
        top: rect.top,
        left: rect.left
      });
    }
  };

  render() {
    return (
      <Provider store={this.store}>
        <ErrorBoundary>
          <ActivitiesView />
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default observer(ActivitiesRootContainer);
