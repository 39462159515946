// @flow

import { observable, action, computed } from 'mobx';
import api from '../util/api';
import ActivityModel from './ActivityModel';

class ActivitiesRootStore {
  VISIBLE_PAGE_POLLING_INTERVAL = 3 * 1000;
  HIDDEN_PAGE_POLLING_INTERVAL = 60 * 1000;

  isCheckingForNewActivitiesStarted: boolean = false;

  @observable isMainContainerVisible = false;
  @observable displayCategory = 'offers';
  @observable offersTotal = 0;
  @observable alertsTotal = 0;
  @observable buttonRect = {
    top: 0,
    left: 0
  };

  isInitialActivitiesFetched = false;

  @observable.shallow offersRepository = new Map();
  @observable.shallow alertsRepository = new Map();

  @observable isLoading = false;

  activityFeedIndicatorElements;

  @action.bound
  getNextActivities() {
    if (!this.isMoreActivitiesAvailable) return;

    const lastActivity = _.last(this.activities);

    if (lastActivity) {
      const ts = lastActivity.userActivityUpdatedAtUtc;

      this.fetchActivities({
        category: this.displayCategory,
        updated_at: ts,
        direction: 'before'
      });
    }
  }

  @action.bound
  toggleMainContainerVisibility() {
    this.isMainContainerVisible = !this.isMainContainerVisible;
  }

  @action.bound
  hideMainContainer() {
    this.isMainContainerVisible = false;
  }

  @action.bound
  async fetchInitialActivities() {
    if (this.isInitialActivitiesFetched) return;
    await this.fetchActivities({ category: 'offers' });
    await this.fetchActivities({ category: 'alerts' });
    this.isInitialActivitiesFetched = true;
  }

  @action.bound
  async fetchActivities(params) {
    if (this.isLoading === true) return;

    this.isLoading = true;

    const response = await api.getActivities(params);

    const newActivities = ActivityModel.createCollection(response.activities);

    const repository = this[`${params.category}Repository`];

    const activitiesById = newActivities.reduce(
      (acc, activity) => ({
        ...acc,
        [activity.id]: activity
      }),
      {}
    );

    repository.merge(activitiesById);

    this.offersTotal = response.offersTotal;
    this.alertsTotal = response.alertsTotal;

    this.isLoading = false;
  }

  @action.bound
  displayOffersCategory() {
    this.displayCategory = 'offers';
  }

  @action.bound
  displayAlertsCategory() {
    this.displayCategory = 'alerts';
  }

  @action.bound
  async checkForNewActivities() {
    const firstActivityUpdatedAtOnServer = await api.checkForNewActivities();

    let firstActivityUpdatedAt;

    if (this.allActivities.length > 0) {
      firstActivityUpdatedAt = Date.parse(
        this.allActivities[0].userActivityUpdatedAtUtc
      );
    } else {
      firstActivityUpdatedAt = +new Date();
    }

    if (firstActivityUpdatedAt < Date.parse(firstActivityUpdatedAtOnServer)) {
      this.getNewActivities(new Date(firstActivityUpdatedAt).toISOString());
    } else {
      this.updateTime();
    }
  }

  @action.bound
  setButtonRect({ top, left }) {
    this.buttonRect.top = top;
    this.buttonRect.left = left;
  }

  getNewActivities = firstActivityUpdatedAt => {
    this.fetchActivities({
      category: 'offers',
      direction: 'after',
      updated_at: firstActivityUpdatedAt
    });

    this.fetchActivities({
      category: 'alerts',
      direction: 'after',
      updated_at: firstActivityUpdatedAt
    });
  };

  @action.bound
  updateTime() {
    this.activities.forEach(activity => {
      activity.updateFromNow();
    });
  }

  markAllOffersAsRead = () => {
    this.markAllAsRead('offers');
  };

  markAllAlertsAsRead = () => {
    this.markAllAsRead('alerts');
  };

  @computed
  get unreadOffersCount() {
    return this.offers.filter(a => !a.isMarkedAsRead).length;
  }

  @computed
  get unreadAlertsCount() {
    return this.alerts.filter(a => !a.isMarkedAsRead).length;
  }

  @action.bound
  async markAllAsRead(category: 'offers' | 'alerts') {
    const activities = this[category];

    try {
      activities.forEach(activity => {
        activity.markAsRead();
      });

      await api.markAllAsRead({ category });

      activities.forEach(activity => {
        activity.commitMarkAsRead();
      });
    } catch (err) {
      activities.forEach(activity => {
        if (!activity.isMarkedAsReadCommitted) {
          activity.markAsUnread();
        }
      });
    }
  }

  markAllAsSeen = () => {
    api.markAllAsSeen();
  };

  @computed
  get activities() {
    return _.sortBy(
      this[this.displayCategory],
      activity => activity.userActivityUpdatedAtUtc
    ).reverse();
  }

  @computed
  get isMoreActivitiesAvailable() {
    let totalActivities = 0;
    if (this.displayCategory === 'offers') {
      totalActivities = this.offersTotal;
    }

    if (this.displayCategory === 'alerts') {
      totalActivities = this.alertsTotal;
    }
    return totalActivities > this.activities.length;
  }

  @computed
  get alerts() {
    return Array.from(this.alertsRepository.values());
  }

  @computed
  get offers() {
    return Array.from(this.offersRepository.values());
  }

  @computed
  get allActivities() {
    return [...this.alerts, ...this.offers].sort((a, b) => {
      const dateA = new Date(a.userActivityUpdatedAtUtc);
      const dateB = new Date(b.userActivityUpdatedAtUtc);
      return dateB - dateA;
    });
  }

  @computed
  get dropdownPositionStyle() {
    if (SS.Viewport.is('xs')) {
      return {
        top: 0,
        right: 0
      };
    }

    return {
      left: this.buttonRect.left - 300,
      top: this.buttonRect.top + 50
    };
  }

  startCheckingForNewActivities() {
    if (this.isCheckingForNewActivitiesStarted) return;

    this.isCheckingForNewActivitiesStarted = true;

    window.Visibility.every(
      this.VISIBLE_PAGE_POLLING_INTERVAL,
      this.HIDDEN_PAGE_POLLING_INTERVAL,
      this.checkForNewActivities
    );
  }

  showActivityFeedIndicator = () => {
    this.activityFeedIndicatorElements.forEach(el =>
      el.classList.remove('hidden')
    );
  };

  hideActivityFeedIndicator = () => {
    this.activityFeedIndicatorElements.forEach(el =>
      el.classList.add('hidden')
    );
  };

  setActivityFeedIndicatorElements = htmlElements => {
    this.activityFeedIndicatorElements = Array.from(htmlElements);
  };
}

export default ActivitiesRootStore;
