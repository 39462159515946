/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

const Show = ({ show, selectedShow, onShowClick }) => {
  const activeClassName = show.id === selectedShow?.id ? 'is-selected' : null

  return (
    <li className={cn('review-modal-v2__search-result review-modal-v2__show-title', activeClassName)}  onClick={() => {
      onShowClick(show)
    }}>

        {show.title}
    </li>
  )
}

export default observer(Show)
