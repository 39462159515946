// @flow

import { CancelToken, isCancel } from 'axios';
import { observable, action, computed } from 'mobx';
import log from 'shared/utils/log';
import { updateAuthToken } from 'shared/utils/helpers';
import UserStore from 'shared/stores/UserStore';
import PlanStore from './PlanStore';
import ViewStore from './ViewStore';
import ErrorsStore from './ErrorsStore';
import NavigationStore from './NavigationStore';
import api from '../utils/api';

const STRIPE_ERROR_MESSAGE =
  'Sorry, but there was an error with Stripe and your order did not go through. Please try again. If you continue to receive this message please contact us at help@show-score.com';

export default class RootStore {
  planStore: PlanStore;
  viewStore: ViewStore;
  userStore: UserStore;
  errorsStore: ErrorsStore;
  navigationStore: NavigationStore;

  @observable isLoading: boolean = false;
  @observable isPaymentProcessing: boolean = false;
  @observable loginError: any = null;
  @observable isSkipLoginForm: boolean = false;
  @observable modalVariant: number = 1;

  isDev: boolean = false;
  paid: boolean = false;
  knownUser: { email: string };
  updateAuthToken = updateAuthToken;

  constructor() {
    this.viewStore = new ViewStore(this);
    this.errorsStore = new ErrorsStore(this);
    this.planStore = new PlanStore(this);
    this.userStore = new UserStore(this);
    this.navigationStore = new NavigationStore(this);
  }

  @action.bound
  init({ isDev, isSignedIn, user, knownUser }) {
    this.isDev = isDev;
    this.knownUser = knownUser;
    this.userStore.init({ user, isSignedIn });
  }

  @action.bound
  skipLoginForm() {
    this.isSkipLoginForm = true;
  }

  @action.bound
  setModalVariant(modalVariant) {
    this.modalVariant = modalVariant;
  }

  @action.bound
  async createMembershipSubscription(token: string) {
    try {
      this.setPaymentProcessing(true);

      this.source = CancelToken.source();

      await api.createMembershipSubscription(
        {
          token,
          planId: this.planStore.selectedPlan.id,
          user: this.userStore.user
        },
        this.source.token
      );

      this.navigationStore.navigateToThanks();
      this.paid = true;
    } catch (err) {
      if (isCancel(err)) {
        this.errorsStore.addError(STRIPE_ERROR_MESSAGE);
        return;
      }

      if (err.response) {
        this.errorsStore.addError(STRIPE_ERROR_MESSAGE);
        return;
      }
      log(err);
    } finally {
      this.setPaymentProcessing(false);
    }
  }

  @computed
  get orderData() {
    let amount = 0;

    if (this.planStore.selectedPlan) {
      amount = this.planStore.selectedPlan.amountCents;
    }

    return {
      email: this.userStore.user.email,
      amount
    };
  }

  @action.bound
  setPaymentProcessing(value: boolean) {
    this.isPaymentProcessing = value;
  }

  @action.bound
  reset() {
    this.loginError = null;
  }
}
