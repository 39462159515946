/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import SelectedAdjectiveList from './SelectedAdjectiveList';
import AdjectiveList from './AdjectiveList';

class AdjectivesPage extends Component {
  componentDidMount() {
    this.props.appStore.trackVirtualPageview('adjectives');
  }

  render() {
    const { appStore } = this.props;
    const { adjectivesPageStore } = appStore;
    const { review } = adjectivesPageStore.appStore;
    const selectedAdjectives = review.adjectives;

    if (review.isAllAdjectivesAdded) {
      return <>
        <div className="review-modal-v2__title">Choose 5 words to describe the show</div>
        <SelectedAdjectiveList
          removeAdjective={adjectivesPageStore.removeAdjective}
          selectedAdjectives={selectedAdjectives}
          getAdjectiveByIndex={review.getAdjectiveByIndex}
        />
        <div className="review-modal-v2__adjectives-done">
          <b className="mb2">Great Job!</b>
          <span>
            Hit next to share what you liked and what you didn’t<br/>
            (so readers can decide if the show is for them!)
          </span>
        </div>
      </>;
    }

    return <>
      <div className="review-modal-v2__title">Choose 5 words to describe the show</div>
      <SelectedAdjectiveList
        advanceAttempted={appStore.advanceAttempted}
        removeAdjective={adjectivesPageStore.removeAdjective}
        selectedAdjectives={selectedAdjectives}
        getAdjectiveByIndex={review.getAdjectiveByIndex}
      />
      <div className="review-modal-v2__common-adjectives">
        <div className="review-modal-v2__common-negative-adjectives">
          <AdjectiveList
            adjectives={adjectivesPageStore.adjectives.positive}
            adjectivesPageStore={adjectivesPageStore}
          />
        </div>

        <div className="review-modal-v2__common-positive-adjectives">
          <AdjectiveList
            adjectives={adjectivesPageStore.adjectives.negative}
            adjectivesPageStore={adjectivesPageStore}
          />
        </div>
      </div>

      <div className="review-modal-v2__custom-adjective">
        <div className="review-modal-v2__custom-adjective-title">Or, create a custom tag</div>

        <div className="review-modal-v2__custom-adjective-form">
          <input
            maxLength="50"
            onChange={(e) => adjectivesPageStore.setCustomAdjective(e.target.value)}
            type="text"
            value={adjectivesPageStore.customAdjective}
          />

          <div
            className="review-modal-v2__custom-adjective-create-btn"
            onClick={adjectivesPageStore.addCustomAdjective}
          >
            Create
          </div>
        </div>
      </div>
    </>;
  }
}

export default inject('appStore')(observer(AdjectivesPage));
