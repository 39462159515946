import React from 'react';
import { observer, inject } from 'mobx-react';
// import tooltips from 'bundles/Membership/components/ChoosePlan/PlansTable/tooltips';
// import Tooltip from 'bundles/Membership/components/ChoosePlan/PlansTable/Tooltip';
import css from './ChoosePlan4.scss';
import MobileHeader from '../shared/MobileHeader';
import MobileCta from '../shared/MobileCta';

function ChoosePlan4({ store: { viewStore, navigationStore } }) {
  return <div className={css.wrap}>
    <MobileHeader title="SAVE" subtitle="The math speaks for itself" />

    <div className={css.section}>
      <h3 className={css['section-title']}>BECOME A SHOW-SCORE &quot;SAVER&quot;</h3>
      <p className={css['section-subtitle']}>
        and start saving on your first ticket!
      </p>
      <ul className={css.list}>
        <li>
          <i className="fal fa-check" />
          <b>Save $10 on each Broadway Member Night ticket!</b>
          <br />
          Standard Member Night tickets are $25. Savers pay $15
        </li>
        <li>
          <i className="fal fa-check" />
          <b>Save $6 on each Beyond Broadway Member Night ticket!</b>
          <br />
          Standard Member Night tickets are $12. Savers pay $6
        </li>
        <li>
          <i className="fal fa-check" />
          <b>Get more Member Nights benefits!</b>
          <br />
          More time to cancel (3 days in advance vs. 4 days) and extra &quot;pardon&quot; for when you need it.
        </li>
      </ul>

      <a
        className={css.button}
        onClick={() => navigationStore.navigateToPlan('saver_monthly_plan')}
        role="button"
        tabIndex={-1}
      >
        <div className={css['button-title']}>START SAVING!</div>
        <div className={css['button-subtitle']}>$2.99/MONTH</div>
      </a>

      <div className={css['cancel-warn']}>Cancel anytime</div>
    </div>

    <MobileCta
      pillLink="/membership?ppm=5"
      title={
        <div>
          WANT SAVINGS <span>AND</span> INSIDER ACCESS?
        </div>
      }
      pill={
        <div>
          Become a Show-Score Patron for <br />
          <span>$10.99/month</span>
        </div>
      }
    />

    <div className={css['cta-note']}>
      Read the{' '}
      <a href="https://support.show-score.com/support/solutions/articles/103000228283-how-do-cancellations-refunds-pardons-and-transfers-work-for-member-nights-">
        Frequently Asked Questions
      </a>{' '}
      or see a{' '}
      <a href="/membership">
        side-by-side comparison
      </a>{' '}
      of our membership programs.
    </div>

    <div
      className={css.footer}
      onClick={viewStore.scrollTop}
      role="button"
      tabIndex={-1}
    >
      <button>
        Scroll to the top <i className="fal fa-arrow-up" />
      </button>
    </div>
  </div>
}

export default inject('store')(observer(ChoosePlan4));
