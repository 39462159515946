import React from 'react';
import { observer } from 'mobx-react';
import css from './Header.scss';

function Header({ subtitle }) {
  return <div className={css.header}>
    <div className={css.logo} />
    {subtitle && <div className={css.subtitle}>{subtitle}</div>}
  </div>
}

export default observer(Header);
