// @flow

import { observable, action, autorun, computed } from 'mobx';
import { createHashHistory as createHistory } from 'history';
import pathToRegexp from 'path-to-regexp';

const history = createHistory({
  basename: '', // The base URL of the app (see below)
  hashType: 'noslash', // The hash type to use (see below)
  queryKey: false
});

export default class NavigationStore {
  @observable.ref currentView;

  ROUTES = {
    root: '/signup-modal',
    payment: '/signup-modal/payment/:plan',
    thanks: '/signup-modal/thanks'
  };

  history;
  unlistenHistory;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.history = history;
  }

  @action.bound
  setCurrentView(view) {
    this.currentView = view;
  }

  @action.bound
  setCurrentViewByPath(path) {
    this.matchRootRoute(path);
    this.matchPaymentRoute(path);
    this.matchThanksRoute(path);
  }

  @action.bound
  navigateToRoot() {
    this.currentView = {
      name: 'root'
    };
    this.rootStore.planStore.choosePlan(null);
  }

  @action.bound
  navigateToThanks() {
    this.currentView = {
      name: 'thanks'
    };
  }

  @action.bound
  navigateToPlan(plan: string) {
    this.rootStore.planStore.choosePlan(plan);
    this.currentView = {
      name: 'payment',
      params: {
        plan
      }
    };
  }

  @action.bound
  matchRootRoute(pathname) {
    if (pathname === '' || pathname === '/' || pathname === '/signup-modal') {
      this.navigateToRoot();
    }
  }

  @action.bound
  matchPaymentRoute(pathname) {
    const rgx = pathToRegexp(this.ROUTES.payment);
    const match = rgx.exec(pathname);
    if (!match) return;
    if (match && !match[1]) return;
    const plan = match[1];
    this.navigateToPlan(plan);
  }

  @action.bound
  matchThanksRoute(pathname) {
    if (pathname === '/signup-modal/thanks') {
      this.navigateToThanks();
    }
  }

  stopRouter = () => {
    if (this.unlistenHistory && typeof this.unlistenHistory === 'function') {
      this.unlistenHistory();
    }
    this.history.push('/');
  };

  startRouter = () => {
    this.unlistenHistory = this.history.listen(location => {
      const newPath = location.pathname;
      if (newPath && newPath !== this.currentPath) {
        this.setCurrentViewByPath(newPath);
      }
    });

    this.history.push(this.ROUTES.root);

    autorun(() => {
      const path = this.currentPath;
      if (path && path !== this.history.location.pathname)
        this.history.push(path);
    });
  };

  @computed
  get currentPath() {
    if (!this.currentView) return '';

    if (this.currentView.name === 'root') return '/signup-modal';

    if (this.currentView.name === 'thanks') return '/signup-modal/thanks';

    if (this.currentView.name === 'payment') {
      if (this.currentView.params.plan) {
        return `/signup-modal/payment/${this.currentView.params.plan}`;
      }
      return '/signup-modal';
    }

    return '';
  }
}
