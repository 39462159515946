import React, { Fragment } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';
import log from 'shared/utils/log';

class Form extends React.Component {
  @observable email = '';
  @observable password = '';

  handleSubmit = async e => {
    try {
      e.preventDefault();
      e.stopPropagation();
      await this.props.onSubmit({
        email: this.email,
        password: this.password
      });
      this.email = '';
      this.password = '';
      document.dispatchEvent(new Event(SS.V2.Events.Navigation.REFRESH));
    } catch (err) {
      log(err);
    }
  };

  setEmail = e => {
    this.email = e.target.value;
  };

  setPassword = e => {
    this.password = e.target.value;
  };

  render() {
    const { isShowKnownUserWidget, loginError } = this.props;

    return (
      <Fragment>
        {!isShowKnownUserWidget ? (
          <div
            className={cn('form-group email optional user_email', {
              'has-error': loginError
            })}
          >
            <label
              className="control-label email optional"
              htmlFor="user_email"
            >
              Email address
            </label>
            <input
              className="form-control string email optional"
              type="email"
              onChange={this.setEmail}
              name="user[email]"
            />
          </div>
        ) : null}

        <div
          className={cn('form-group password optional user_password mb0', {
            'has-error': loginError
          })}
        >
          <label
            className="control-label password optional"
            htmlFor="user_password"
          >
            Password
          </label>
          <input
            name="user[password]"
            className="form-control password optional"
            type="password"
            onChange={this.setPassword}
          />
          {loginError && (
            <span className="help-block">Invalid email or password.</span>
          )}
        </div>
        <a
          className="promo-ticket-page__sign-in-forgot-password"
          href="/users/password/new"
        >
          Forgot password?
        </a>
        <button
          className="ss-btn ss-btn-primary ss-btn-large btn-block js-promo-ticket-page__submit-login"
          type="button"
          onClick={this.handleSubmit}
        >
          Sign in
        </button>
      </Fragment>
    );
  }
}

export default observer(Form);
