import React from 'react';
import { observer, inject } from 'mobx-react';
import css from './Thanks.scss';
import Header from '../Checkout/Header';

function Subtitle({ planCodename }) {
  const isAnnual = planCodename.match(/yearly/i);
  const isSaver = planCodename.match(/saver/i);

  return (
    <div className={css.subtitle}>
      You are now {isAnnual ? 'an annual' : 'a'} <br />
      Show-Score {isSaver ? 'Saver' : 'Patron'}.
    </div>
  );
}

function Thanks({ store: { planStore } }) {
  return <div className={css.wrap}>
    <Header />
    <h2 className={css.title}>THANKS!</h2>
    <Subtitle planCodename={_.get(planStore, 'selectedPlan.codename')} />
    <div>
      You will immediately start to receive exciting benefits. If you have any
      questions, please{' '}
      <a
        href="https://support.show-score.com/support/solutions/folders/103000597545"
        target="_blank"
        rel="noopener noreferrer"
      >
        Read the FAQs
      </a>{' '}
      or email help@show-score.com.
    </div>
    <div className={css.actions}>
      <a
        href="/promotions"
        className="ss-btn ss-btn-primary ss-btn-lg btn-block"
      >
        SEE YOUR OFFERS
      </a>
    </div>
  </div>
}

export default inject('store')(observer(Thanks));
