/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import ShowFilter from './ShowFilter';
import ShowList from './ShowList';
import TopShows from './TopShows';

class ChooseShowPage extends Component {
  componentDidMount() {
    this.props.appStore.trackVirtualPageview('choose-show');
    this.props.appStore.chooseShowPageStore.init();
  }

  render() {
    const { appStore } = this.props;
    const { chooseShowPageStore } = appStore;

    return <>
      <div className="review-modal-v2__title">
        Score at least 2 shows to earn free&nbsp;
        <br className="hidden-xs"/>
        tickets to shows you’ll love
      </div>

      {appStore.topShows.length > 0 &&
        <TopShows shows={appStore.topShows} onTopShowClick={appStore.handleTopShowClick}/>}

      <div className="review-modal-v2__search">
        <ShowFilter onFilterChange={chooseShowPageStore.setFilter} filterValue={chooseShowPageStore.filter}/>
        <ShowList
          onShowClick={chooseShowPageStore.handleShowClick}
          selectedShow={chooseShowPageStore.selectedShow}
          shows={chooseShowPageStore.shows}
        />
      </div>
      <div
        className="review-modal-v2__start-btn"
        onClick={() => {
          appStore.goToPage(1);
        }}
      >
        Start Reviewing
      </div>
    </>;
  };
}

export default inject('appStore')(observer(ChooseShowPage));
