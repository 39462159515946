/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames'

const PageControlsDot = ({page, appStore}) => {
const isActive = appStore.currentPage === page

return (
    <a
    className={cn('review-modal-v2__nav-dot', {
      'is-active': isActive,
    })}
    onClick={() => {
      if (isActive) return

      appStore.goToPage(page)
    }}
  >
    <i className="far fa-star" />
  </a>
  )}

export default observer(PageControlsDot);
