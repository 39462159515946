/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';

const AdjectiveItem = ({adjective, removeAdjective}) => (
    <div
      className="review-modal-v2__selected-adjective"
      onClick={() => {
        removeAdjective(adjective);
      }}
    >
      <span className="review-modal-v2__selected-adjective-text">{adjective}</span>
      <i className="fa-times fal review-modal-v2__selected-adjective-remove-btn" />
    </div>
  );

export default observer(AdjectiveItem);
