import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import FbLoginBtn from 'shared/components/FbLoginBtn';
import KnownUserWidget from 'shared/components/KnownUserWidget';
import css from './Login.scss';
import Form from './Form';

class Login extends React.Component {
  @observable isShowKnownUserWidget = true;

  handleNotYouClick = () => {
    this.isShowKnownUserWidget = false;
  };

  render() {
    const { store } = this.props;

    const isShowKnownUserWidget =
      !_.isEmpty(store.knownUser) && this.isShowKnownUserWidget;

    return (
      <div className={css.wrap}>
        <div className={css['login-form']}>
          <div className={css.heading}>Sign in with your email address:</div>
          <hr className={css.hr} />
          {isShowKnownUserWidget && (
            <KnownUserWidget
              knownUser={store.knownUser}
              onNotYouClick={this.handleNotYouClick}
            />
          )}

          <Form
            loginError={store.loginError}
            isShowKnownUserWidget={isShowKnownUserWidget}
            onSubmit={store.userStore.login}
          />
        </div>
        <div className={css['fb-action-title']}>Or sign in with Facebook</div>
        <div className={css['fb-button-wrap']}>
          <FbLoginBtn />
        </div>
        <div className={css.or} />
        <div className={css['signup-action-title']}>
          Not yet a Show-Score member? It’s free to join!
        </div>
        <div>
          <a
            href="/users/sign_up"
            className="ss-flat-btn ss-flat-btn-secondary btn-block -normal"
          >
            Create An Account
          </a>
        </div>
      </div>
    );
  }
}

export default inject('store')(observer(Login));
