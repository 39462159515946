import React from 'react';
import { observer } from 'mobx-react';

function ActivityItemActions({ activity }) {
  return <div className="col-xs-1 activities-actions">
    {activity.isMarkedAsRead || (
      <a
        href="#"
        className="hidden-xs"
        title="Mark as Read"
        aria-label="Mark as read"
        onClick={activity.markAsReadOnServer}
      >
        <i className="fa fa-check-circle-o" />
      </a>
    )}
  </div>
}

export default observer(ActivityItemActions);
