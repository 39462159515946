import React from 'react';
import { observer, inject } from 'mobx-react';
import SignupModal from './SignupModal';

function SignupRootView({ store: { viewStore: { displayModal } } }) {
    if (!displayModal) {
      return null;
    }

    return <SignupModal />;
}

export default inject('store')(observer(SignupRootView));
