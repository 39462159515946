import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import css from './ActivitiesDropdown.scss';
import ActivitiesList from './ActivitiesList';
import ActivitiesDropdownHeader from './ActivitiesDropdownHeader';
import ActivitiesDropdownFooter from './ActivitiesDropdownFooter';
import LoadingIndicator from './LoadingIndicator';

class ActivitiesDropdown extends Component {
  dom = {};

  componentDidMount() {
    const { store } = this.props;

    store.fetchInitialActivities();
    this.dom.feed.addEventListener('scroll', this.onFeedScroll);
    document.addEventListener('mousedown', this.handleClickOutside);
    store.markAllAsSeen();
    store.startCheckingForNewActivities();
    store.hideActivityFeedIndicator();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onFeedScroll = () => {
    const rect = this.dom.feed.getBoundingClientRect();

    if (this.dom.feed.scrollTop + rect.height >= this.dom.feed.scrollHeight) {
      this.props.store.getNextActivities();
    }
  };

  handleClickOutside = event => {
    const alertButtonsCollection = document.getElementsByClassName(
      'js-activity-feed__dropdown-btn'
    );

    const alertButtons = [].slice.call(alertButtonsCollection);

    if (alertButtons.some(b => b.contains(event.target))) {
      return;
    }

    if (this.dom.root && !this.dom.root.contains(event.target)) {
      this.props.store.hideMainContainer();
    }
  };

  render() {
    const {
      store,
      store: { activities, isMoreActivitiesAvailable, isLoading }
    } = this.props;

    return (
      <div
        className={css.wrap}
        ref={ref => {
          this.dom.root = ref;
        }}
        style={store.dropdownPositionStyle}
      >
        <ActivitiesDropdownHeader />
        <div
          className={css['feed-list']}
          ref={ref => {
            this.dom.feed = ref;
          }}
        >
          <ActivitiesList activities={activities} />
        </div>
        {isLoading && <LoadingIndicator />}
        {isMoreActivitiesAvailable && <ActivitiesDropdownFooter />}
      </div>
    );
  }
}

export default inject('store')(observer(ActivitiesDropdown));
